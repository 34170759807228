* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-touch-callout: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "LatoRegular";
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype"),
    url("../fonts/Inter/Inter-Bold.ttf") format("truetype"),
    url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype"),
    url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype"),
    url("../fonts/Inter/Inter-Light.ttf") format("truetype"),
    url("../fonts/Inter/Inter-Medium.ttf") format("truetype"),
    url("../fonts/Inter/Inter-Regular.ttf") format("truetype"),
    url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype"),
    url("../fonts/Inter/Inter-Thin.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf")
      format("truetype"),
    url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-Thin.ttf") format("truetype"),
    url("../fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}

.search-bat-property-type-select-field {
  &:focus {
    border: none;
    box-shadow: none !important;
  }
}

.chakra-checkbox__control {
  div {
    svg {
      color: #fff;
    }
  }
}

.Toastify__toast-container {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Toastify__toast {
    width: max-content;

    .Toastify__toast-body {
      div {
        white-space: pre-line;
      }
    }
  }
}

.chakra-checkbox__control {
  width: 20px !important;
  height: 20px !important;
  background: #fff;

  &[data-checked] {
    background: #bce954 !important;
    border-color: #bce954 !important;
  }
}

.chakra-wrap__list {
  .chakra-input {
    margin: 0;
    margin-top: 4px;

    &:disabled {
      background: var(--chakra-colors-background-grey-250);
      color: var(--chakra-colors-text-third);
      opacity: 0.8;
    }
  }
}

.chakra-radio {
  margin-top: 0.5rem !important;
}

.slick-slider {
  height: 100%;
  line-height: 0;
}

.slick-slide > div {
  > div {
    height: 100%;
  }
  height: 100%;
}

.slick-dots {
  overflow-x: scroll;

  button {
    color: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.slick-arrow {
  width: max-content !important;
}

.slick-next {
  right: 0 !important;

  &::before {
    display: none;
  }
}

.slick-prev {
  left: 0 !important;

  &::before {
    display: none;
  }
}

.slick-track {
  height: 100%;
}

.slick-list {
  height: 100%;
}
.slide-text .product-text .chakra-text {
  max-width: 200px;
}
.truncate-text {
  color: #99a4ae;
  font-weight: 700;
  line-height: 24px;
  font-family: Inter, sans-serif;
  width: 200px;
  font-size: 18px;
  display: block;
}
.city-zip p.chakra-text {
  display: inline;
  color: #99a4ae;
  font-weight: 700;
  line-height: 24px;
  font-family: Inter, sans-serif;
  width: 200px;
  font-size: 18px;
}

.city-zip p.chakra-text:first-child {
  margin-right: 8px;
}

// sell form css error
.sqft-error + .chakra-form__error-message {
  bottom: -100%;
}

.css-38z9cr {
  margin-top: 150px !important;
}

.css-15ebwvi {
  bottom: 0px !important;
}

.css-9jwb71 {
  flex-direction: row !important;
  justify-content: center !important;
}
.error-show label {
  margin-bottom: 22px;
  position: relative;
  top: 12px;
}
.error-show .product-text {
  position: absolute;
  bottom: -25px;
}
.error-show {
  position: relative;
  margin-right: 10px;
}
.feature-data {
  margin-top: 0px !important;
  position: relative;
  top: 45px;
}
.search-filter {
  flex-direction: column !important;
  margin-top: 75px !important;
}

.feature-data {
  margin-top: 0px !important;
  position: relative;
  top: 45px;
}
.search-filter {
  flex-direction: column !important;
  margin-top: 75px !important;
}
.error-show .product-text {
  position: absolute;
  bottom: -25px;
}
.error-show {
  position: relative;
  margin-right: 10px;
}
@media only screen and (max-width: 1000px) and (max-width: 1100px) {
  .animation_main_box .content-box .heading {
    padding-top: 120px !important;
  }
}
@media only screen and (max-width: 992px) {
  .animation_main_box .content-box .heading {
    padding-top: 120px !important;
  }
  .animated-hd {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .soc-main {
    max-width: 100% !important;
  }
  .social-signup-text .SocialImg.five,
  .social-signup-text .SocialImg.three {
    max-width: 120px;
  }
  .social-signup-text .SocialImg.four {
    max-width: 140px;
  }
  .soc-img img.SocialImg {
    margin: auto;
  }
  .soc-img-box {
    align-items: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .animation_main_box .content-box .heading {
    padding-top: 60px !important;
  }
  .social-signup-text .SocialImg {
    max-width: 240px;
  }
  .social-signup-text .SocialImg.four {
    max-width: 55px;
  }
  .social-signup-text .SocialImg.five,
  .social-signup-text .SocialImg.three {
    max-width: 45px;
  }
  .soc-img img.SocialImg {
    margin: auto;
  }
  .soc-img-box {
    width: 55% !important;
  }
  // second screen
  .sc-two-main {
    margin: auto;
    max-width: 50% !important;
    top: 20px;
    position: relative;
  }
  .sc-two-sm1 {
    top: 10px !important;
    width: 100px !important;
    left: 21% !important;
  }
  .sc-two-sm3 {
    bottom: -45px !important;
    width: 80px !important;
    left: calc(50% - 40px) !important;
  }
  .sc-two-sm2 {
    top: 10px !important;
    width: 100px !important;
    right: 21% !important;
  }
  .screen-two-hd {
    font-size: 16px !important;
    margin-top: 30px !important;
    padding-bottom: 7% !important;
    text-align: center !important;
  }
  // three screen
  .sc-three-main {
    width: 160px !important;
    position: relative;
    top: 24px;
  }
  .screen-three-img-container {
    width: 70% !important;
  }
  .sc-three-sm1,
  .sc-three-sm2,
  .sc-three-sm3 {
    width: 80px !important;
  }
  .sc-three-sm1 {
    top: 25px !important;
  }
  .screen-three-hd {
    position: relative;
    top: 20px;
    font-size: 18px;
  }
  // four screen
  .sc-four-main {
    width: 30% !important;
    position: relative;
    bottom: -10px;
  }
  .screen-four-hd {
    font-size: 18px !important;
    position: relative;
    top: 20px;
  }
  // five screen
  .screen-five-hd {
    font-size: 18px !important;
    position: relative;
    top: 25px;
  }
  .sc-five-main {
    width: 40% !important;
    position: relative;
    top: 25px;
  }
  .learn-more-btn {
    padding: 12px !important;
  }
  // six screen
  .sc-six-main {
    width: 50% !important;
    margin: auto;
  }
}
@media only screen and (max-width: 30em) {
  .Toastify__toast-container {
    width: 100%;

    .Toastify__toast {
      .Toastify__toast-body {
        div {
          font-size: 10px;
        }
      }
    }
  }
}
